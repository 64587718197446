const bloccoMostre = () => {
  //   // JS
  //   document.addEventListener("DOMContentLoaded", () => {
  //     const blocks = document.querySelectorAll(".mostre-collettive");
  //     if (blocks.length > 0) {
  //       blocks.forEach((block) => {
  //         const wrappers = block.querySelectorAll(".wrapper");
  //         if (wrappers.length > 0) {
  //           wrappers.forEach((wrapper) => {
  //             const toggler = wrapper.querySelector(".toggler-description");
  //             const text = wrapper.querySelector(".descrizione-mostre");
  //             if (toggler && text) {
  //               toggler.addEventListener("click", () => {
  //                 text.classList.toggle("d-none");
  //               });
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });

  // JQuery
  $(() => {
    if ($(".mostre-collettive")) {
      $(".mostre-collettive").each(function () {
        const $wrappers = $(this).find(".wrapper");
        if ($wrappers) {
          $wrappers.each(function () {
            const $wrapper = $(this);
            const $toggler = $wrapper.find(".toggler-description");
            // const $text = $(this).find(".descrizione-mostre");
            if ($toggler) {
              $toggler.click(function () {
                $wrapper.toggleClass("open");
              });
            }
          });
        }
      });
    }
  });
};

export default bloccoMostre;
